<template>
    <div class="quality-stats">
        <div v-if="error">
            {{ labels.errorMessage }}
        </div>
        <template v-else>
            <div>{{ labels.dateLabel }}: {{ new Date().toString().substring(0, 15) }}</div>
            <div class="quality-stats__season-menu">
                <span class="quality-stats__season-label">{{ labels.seasonLabel }}:</span>
                <vit-dropdown ref="dropdown" :label="currentSeasonLabel" inline light>
                    <dropdown-item
                        v-for="s in seasonData"
                        :key="s.seasonId"
                        :label="s.description"
                        @click="changeSeason(s)"
                        :selected="season === s.description" />
                    <template v-if="loadingList">
                        <loading-spinner></loading-spinner>
                    </template>
                </vit-dropdown>
            </div>
            <template v-if="commodities && commodities.barley && commodities.barley.dataSorted.length">
                <vit-tabs>
                    <vit-tab v-for="(tab, t) in commodities" :key="`qt#${t}`" :title="tab.label">
                        <div class="mt-3 button" @click="exportXlsx(tab)">
                            {{ labels.exportLabel }}
                        </div>
                        <table class="table table-responsive-lg quality-stats__table">
                            <colgroup>
                                <col span="1">
                                <col span="1" style="width: 25%;">
                                <col span="1">
                                <col span="1">
                                <col span="1">
                                <col span="1">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th @click="sortColumn(t, 'grade')" class="quality-stats__column text-nowrap">
                                        {{ labels.gradeLabel }}
                                        <icon
                                            name="icn-sort-arrow"
                                            :class="`quality-stats__sort-arrow quality-stats__sort-arrow-${getSortDir(tab, 'grade')}`" />
                                    </th>
                                    <th @click="sortColumn(t, 'site')" class="quality-stats__column">
                                        {{ labels.siteLabel }}
                                        <icon
                                            name="icn-sort-arrow"
                                            :class="`quality-stats__sort-arrow quality-stats__sort-arrow-${getSortDir(tab, 'site')}`" />
                                    </th>
                                    <th
                                        v-for="column in tab.columns"
                                        :key="`th-${column.key}`"
                                        class="text-right quality-stats__column"
                                        @click="sortColumn(t, column.key)">
                                        <span class="text-nowrap">{{ column.label }}</span><br>
                                        <span class="text-nowrap">
                                            <icon
                                                name="icn-sort-arrow"
                                                :class="`quality-stats__sort-arrow quality-stats__sort-arrow-${getSortDir(tab, column.key)}`" />
                                            {{ column.unit }}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="data in tab.dataSorted" :key="`${data.grade}-${data.site}`">
                                    <td>{{ data.grade }}</td>
                                    <td>{{ data.siteDescription }}</td>
                                    <td
                                        v-for="column in tab.columns"
                                        :key="`${column.key}-${data.grade}-${data.site}`"
                                        class="text-right">
                                        {{ data.qualities.find(x => x.testType === column.key).result.toFixed(2) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </vit-tab>
                </vit-tabs>
            </template>
            <template v-else>
                <loading-spinner></loading-spinner>
            </template>
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { utils, writeFile } from 'xlsx';
import QualityStats, { CommodityHeaders } from '../../lib/QualityStats';
import Icon from '../atoms/Icon.vue';
import LoadingSpinner from '../atoms/LoadingSpinner.vue';
import DropdownItem from '../base/VitDropdownItem.vue';
import VitDropdown from '../base/VitDropdown.vue';
import VitTabs from '../base/VitTabs.vue';
import VitTab from '../base/VitTab.vue';

export default defineComponent({
    components: { VitTab, VitTabs, VitDropdown, Icon, DropdownItem, LoadingSpinner },
    props: {
        apiUrls: {
            required: true,
            type: Object as PropType<{barleyUrl: string; wheatUrl: string; canolaUrl: string; seasonsUrl: string;}>
        },
        labels: {
            required: true,
            type: Object as PropType<{barleyLabel: string; wheatLabel: string; canolaLabel: string; gradeLabel: string; siteLabel: string; errorMessage: string; dateLabel: string; seasonLabel: string; exportLabel: string;}>
        },
        barleyHeader: { required: true, type: Object as PropType<CommodityHeaders> },
        wheatHeader: { required: true, type: Object as PropType<CommodityHeaders> },
        canolaHeader: { required: true, type: Object as PropType<CommodityHeaders> }
    },
    data() {
        return {
            allSeasonData: [],
            seasonData: [],
            season: null,
            loadingList: true,
            error: false,
        };
    },
    async created() {
        try {
            // Get all seasons available
            this.allSeasonData = await QualityStats.getSeasonsYears(this.apiUrls.seasonsUrl);
            for (const season of this.allSeasonData) {
                const currentSeason = { ...season };
                currentSeason.barley = await QualityStats.getCommodityData(this.apiUrls.barleyUrl, season.season);
                currentSeason.wheat = await QualityStats.getCommodityData(this.apiUrls.wheatUrl, season.season);
                currentSeason.canola = await QualityStats.getCommodityData(this.apiUrls.canolaUrl, season.season);
                if(currentSeason.barley.length || currentSeason.wheat.length || currentSeason.canola.length) {
                    this.seasonData = [...this.seasonData, currentSeason];
                    if( !this.season) {
                        this.season = currentSeason;
                    }
                }
            }
            this.loadingList = false;
        } catch (error) {
            this.error = true;
            this.loadingList = false;
        }
    
    },

    methods: {
        // update the sort column and direction. returns false if the sort order has been reverted to default, true otherwise
        setSortCol(commodity, col) {
            if (this.commodities[commodity].sortCol === col) {
                this.commodities[commodity].sortDesc = !this.commodities[commodity].sortDesc;
                if (!this.commodities[commodity].sortDesc) {
                    this.sortDefault(commodity);
                    return false;
                }
            } else {
                this.commodities[commodity].sortCol = col;
                this.commodities[commodity].sortDesc = false;
            }
            return true;
        },

        // revert to default order
        sortDefault(commodity) {
            this.commodities[commodity].sortCol = 'default';
            this.commodities[commodity].dataSorted = [...this.commodities[commodity].data];
        },

        // sort function for first to columns (no nesting)
        sortGradeSite(commodity, col) {
            const sortBefore = this.commodities[commodity].sortDesc ? 1 : -1;
            const sortAfter = 0 - sortBefore;
            this.commodities[commodity].dataSorted.sort((a, b) => (a[col] < b[col] ? sortBefore : sortAfter));
        },

        // sort function for values
        sortColumn(commodity, column) {
            if (!this.setSortCol(commodity, column)) {
                return;
            }
            // first 2 columns have a different sort function
            if (column === 'grade' || column === 'site') {
                this.sortGradeSite(commodity, column);
                return;
            }
            const sortBefore = this.commodities[commodity].sortDesc ? 1 : -1;
            const sortAfter = 0 - sortBefore;

            this.commodities[commodity].dataSorted.sort((a, b) => {
                const c = a.qualities.find(x => x.testType === column);
                const d = b.qualities.find(x => x.testType === column);
                if (c && d) {
                    return c.result < d.result ? sortBefore : sortAfter;
                }
                if (c && !d) {
                    return sortBefore;
                }
                if (!c && d) {
                    return sortAfter;
                }
                return 0;
            });
            this.$forceUpdate();
        },

        getSortDir(tab, col) {
            if (tab.sortCol !== col || tab.sortCol === 'default') {
                return 'default';
            }
            return tab.sortDesc ? 'desc' : 'asc';
        },

        exportXlsx(tab) {
            const exportData = [
                [this.labels.gradeLabel, this.labels.siteLabel, ...tab.columns.map(x => x.label)].map(s => ({
                    v: s,
                    t: 's',
                    s: {
                        fill: {
                            patternType: 'solid',
                            bgColor: { rgb: 'aaaaaa' }
                        }
                    }
                }))
            ];
            tab.data.forEach(data => {
                const row = [data.grade, data.siteDescription];
                tab.columns.forEach(col => {
                    row.push(data.qualities.find(x => x.testType === col.key).result);
                });
                exportData.push(row);
            });
            /* convert from array of arrays to workbook */
            const worksheet = utils.aoa_to_sheet(exportData);
            worksheet['!cols'] = new Array(tab.columns.length + 2).fill({ width: 25 });
            const wb = utils.book_new();
            utils.book_append_sheet(wb, worksheet, 'Sheet');
            /* generate file and send to client */
            writeFile(wb, `Viterra_QualityStats_${tab.label}.xlsx`);
        },

        changeSeason(season) {
            this.season = season;
            this.$refs.dropdown.toggleDropdown();
            this.$forceUpdate();
        },
    },

    computed: {
        commodities() {
            if (!this.season) return {};
            const { barley, wheat, canola } = this.season;
            return {
                barley: {
                    label: this.labels.barleyLabel,
                    data: barley || [],
                    dataSorted: [...(barley || [])],
                    columns: Object.entries(this.barleyHeader).map(([key, value]) => ({
                        key,
                        label: typeof value === 'string' ? value.split('(')[0].trim() : '',
                        unit: typeof value === 'string' && value.match(/\(([^)]+)\)/)?.[1] || ''
                    })),
                },
                wheat: {
                    label: this.labels.wheatLabel,
                    data: wheat || [],
                    dataSorted: [...(wheat || [])],
                    columns: Object.entries(this.wheatHeader).map(([key, value]) => ({
                        key,
                        label: typeof value === 'string' ? value.split('(')[0].trim() : '',
                        unit: typeof value === 'string' && value.match(/\(([^)]+)\)/)?.[1] || ''
                    })),
                },
                canola: {
                    label: this.labels.canolaLabel,
                    data: canola || [],
                    dataSorted: [...(canola || [])],
                    columns: Object.entries(this.canolaHeader).map(([key, value]) => ({
                        key,
                        label: typeof value === 'string' ? value.split('(')[0].trim() : '',
                        unit: typeof value === 'string' && value.match(/\(([^)]+)\)/)?.[1] || ''
                    })),
                }
            };
        },

        currentSeasonLabel() {
            return this.season ? this.season.description : '2024/25';
        }
    }
});
</script>