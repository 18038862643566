import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "country-popup" }
const _hoisted_2 = { class: "country-popup__container" }
const _hoisted_3 = { class: "country-popup__inner" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_vit_dropdown = _resolveComponent("vit-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.introText), 1),
        (_ctx.countries.length)
          ? (_openBlock(), _createBlock(_component_vit_dropdown, {
              key: 0,
              label: _ctx.$capitalize(_ctx.selectedCountry.name) || _ctx.dropdownPlaceholder,
              class: "country-popup__dropdown",
              "close-item": "",
              light: "",
              inline: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
                  return (_openBlock(), _createBlock(_component_dropdown_item, {
                    key: country.isoCode,
                    label: country.name,
                    selected: _ctx.selectedCountry === country,
                    onClick: ($event: any) => (_ctx.selectCountry(country))
                  }, null, 8, ["label", "selected", "onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "button country-popup__confirm",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args))),
          disabled: _ctx.selectedCountry.isoCode.length === 0
        }, _toDisplayString(_ctx.buttonLabel), 9, _hoisted_4)
      ])
    ])
  ]))
}