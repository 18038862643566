import axios, { CancelToken, CancelTokenSource } from 'axios';


export interface SeasonInfo {
    seasonId: string;
    season: string;
    description: string;
}

// for table headers
export interface CommodityHeaders {
    MO?: string;
    TW?: string;
    PR?: string;
    SC?: string;
    OA?: string;
    IM?: string;
}

export default class QualityStats {

    public static async getSeasonsYears(baseurl: string): Promise<SeasonInfo[]> {
        try {
            const response = await axios.get(baseurl);
            // order seasons from most recent to oldest 
            const orderedSeasons = response.data.sort((a, b) => b.season.localeCompare(a.season));
            return orderedSeasons;
        } catch (e) {
            return [];
        }
    }

    public static async getCommodityData(baseurl: string, year: string) {
        try {
            const response = await axios.get(`${baseurl}?${this.getCommodityQuery(year)}`);
            return response.data;
        } catch (e) {
            return [];
        }
    }

    public static getCommodityQuery(year: string) {
        return Object.entries({
            $filter: `Season eq '${year}' `,
            $orderby: 'SiteDescription,Grade'
        }).map(x => `${x[0]}=${encodeURI(x[1])}`).join('&');
    }
}