<template>
    <div>
        <div class="section__container" v-if="disclaimer !== DISCLAIMER.DISAGREED && disclaimer !== DISCLAIMER.NOACCESS && hasRedirectUrl">
            <div class="section__content d-flex justify-content-end mb-4">
                <slot name="buttons" :confirm="confirm" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Helper from '../lib/Helper';

export default defineComponent({
    props: {
        countries: { type: Array as () => Array<{ name: string, isoCode: string, disclaimer: string }> },
        editMode: { type: Boolean, default: false }
    },
    data() {
        return {
            disclaimer: '',
            DISCLAIMER: {
                NOACCESS: 'no-access',
                USA: 'usa',
                OTHER: 'other',
                DISAGREED: 'disagreed'
            }
        };
    },

    mounted() {
        const cookie = Helper.getCookie('country');
        const country = this.countries.find(c => c.isoCode === cookie);
        if (country) {
            this.disclaimer = country.disclaimer;
        }
        const disclaimerCookie = Helper.getCookie('disclaimer');
        if (disclaimerCookie) {
            if (disclaimerCookie === 'false') {
                this.disclaimer = this.DISCLAIMER.DISAGREED;
            } else if (disclaimerCookie === 'true') {
                this.confirm(true);
            }
        }
    },

    methods: {
        confirm(agree) {
            if (this.editMode) {
                return;
            }
            Helper.setCookie('disclaimer', agree ? 'true' : 'false');
            if (agree) {
                const fullUrl = window.location.href; 
                const match = fullUrl.match(/[?&]original_url=([^&]*)/);
                const redirectUrl = match ? decodeURIComponent(match[1]) : null;
                if (redirectUrl) {
                    location.href = redirectUrl;
                }
            } else {
                this.disclaimer = this.DISCLAIMER.DISAGREED;
                document.scrollingElement.scrollTop = 0;
                location.reload();
            }
        }
    },

    computed: {
        hasRedirectUrl() {
            const urlParams = new URLSearchParams(window.location.search);
            const redirectUrl = urlParams.get('original_url');
            return !!redirectUrl;
        }
    }
});
</script>
