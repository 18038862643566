import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "btn dropdown-toggle btn-secondary btn-block dropdown__button" }
const _hoisted_2 = { class: "dropdown__button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_collapse_transition = _resolveComponent("collapse-transition")!

  return (_openBlock(), _createElementBlock("div", {
    onShow: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onShow && _ctx.onShow(...args))),
    class: _normalizeClass(["dropdown b-dropdown", _ctx.classes]),
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1)
    ]),
    _createVNode(_component_collapse_transition, null, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          (_ctx.isOpen)
            ? (_openBlock(), _createElementBlock("ul", {
                key: 0,
                class: "dropdown-menu dropdown__menu show",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleItemClick && _ctx.handleItemClick(...args)))
              }, [
                _renderSlot(_ctx.$slots, "default")
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 3
    })
  ], 34))
}